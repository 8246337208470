import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { SCREENING_STATUSES } from '@theguarantors/tg-modern-business';
import { Datepicker, InfoContainer, Box, Select, Button } from '@theguarantors/ui-kit-v3';

import { EditableField } from '../../../../components/editable-field/editable-field.component';
import {
  validateAddressUnit,
  validateFreeRent,
  validateLeaseEndDate,
  validateLeaseStartDate,
  validateMonthlyRent,
  validatePrepaidRent,
} from '../../validations';
import { formatCurrencyToNumberString } from '../../../../utils/format-currency-number';
import { formatIsoStringDate, getTruthyValuesCount } from '../../details.utils';
import { DetailsChangesKey, LeaseInfoProps } from '../../details.types';
import { add, format } from 'date-fns';
import { DateFormats, MAX_DATE_YEAR } from '../../details.constants';
import { Icon } from '@theguarantors/ui-kit-v3/icons';
import { cloneDeep } from '../../../../utils';
import { FeatureFlag, useFeatureFlag } from '../../../../hooks/use-feature-flag';
import { EvaluationResultModal } from '../Modals/evaluation-result-modal';
import { useEvaluateApplication } from '../../hooks/use-evaluate-application';
import { useProcessEvaluatedApplication } from '../../hooks/use-process-evaluated-application';
import { formatField } from './coverage-input-info.utils';
import {
  allowedApplicationStatusesForPreApprovalEvaluation,
  allowedScreeningStatusesForFinalEvaluation,
} from './coverage-input-info.constants';

export const CoverageInputInfo: React.FC<LeaseInfoProps> = ({
  application,
  changes: c = {},
  setChanges,
  coverageInputDifferences,
  refetch,
  ...props
}) => {
  const [displayScreeningStatusField] = useFeatureFlag(FeatureFlag.LL_SCREENING_STATUS);
  const [displayNewDetailsFeatureFlag] = useFeatureFlag(FeatureFlag.DISPLAY_NEW_DETAILS);
  const [coverageFieldsEnabled] = useFeatureFlag(FeatureFlag.APPLICATION_LEVEL_COVERAGE_CHANGE);
  const [unknownScreeningStatusFlag] = useFeatureFlag(FeatureFlag.REN_3300_SCREENING_STATUS);
  const [useDefaultRulesForUnkownScreeningStatusFlag] = useFeatureFlag(
    FeatureFlag.USE_DEFAULT_RULES_FOR_UNKNOWN_SCREENING_STATUS,
  );
  const [addressUnitRequired] = useFeatureFlag(FeatureFlag.ADDRESSUNIT_REQUIRED);

  const changes = cloneDeep(c);
  const lease = application?.lease;
  const leaseChanges = cloneDeep(changes?.[DetailsChangesKey.LEASE] ?? {});
  const applicationChanges = cloneDeep(changes?.[DetailsChangesKey.APPLICATION] ?? {});

  const [isEvaluationResultModalOpen, setIsEvaluationResultModalOpen] = useState(false);
  const { evaluateApplication, data: evaluationData, loading: evaluationLoading } = useEvaluateApplication();
  const {
    processEvaluatedApplication,
    data: processingData,
    loading: processingLoading,
  } = useProcessEvaluatedApplication();

  useEffect(() => {
    if (evaluationData?.approvalStatus || evaluationData?.error) {
      setIsEvaluationResultModalOpen(true);
    }
  }, [evaluationData, setIsEvaluationResultModalOpen]);

  useEffect(() => {
    if (processingData?.applicationStatus) {
      refetch();
      setIsEvaluationResultModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingData, application]);

  const screeningStatusChangeHandler = useCallback(
    (value) => {
      applicationChanges && (applicationChanges.screeningStatus = { value });

      setChanges(DetailsChangesKey.APPLICATION, applicationChanges);
    },
    [applicationChanges, setChanges],
  );

  const leaseStartDateChangeHandler = useCallback(
    (date: Date | null) => {
      const leaseStartError = validateLeaseStartDate(date);
      const isLeaseStartChanged =
        (date && format(date, DateFormats.WITH_DASHES)) !==
        (lease?.leaseStartDate && format(new Date(lease?.leaseStartDate), DateFormats.WITH_DASHES));
      const isLeaseEndDateTouched = Object.prototype.hasOwnProperty.call(leaseChanges, 'leaseEndDate');

      if (!date) {
        return setChanges(DetailsChangesKey.LEASE, {
          ...leaseChanges,
          leaseStartDate: { value: date, error: leaseStartError },
        });
      }

      if (!isLeaseStartChanged) {
        delete leaseChanges?.leaseStartDate;
        return setChanges(DetailsChangesKey.LEASE, leaseChanges);
      }

      if (!isLeaseEndDateTouched) {
        return setChanges(DetailsChangesKey.LEASE, {
          ...leaseChanges,
          leaseStartDate: { value: date, error: leaseStartError },
          leaseEndDate: { value: add(date, { years: 1, days: -1 }) },
        });
      }

      const leaseEndDate = leaseChanges?.leaseEndDate?.value ?? null;
      const leaseEndError = validateLeaseEndDate(leaseEndDate, date);

      setChanges(DetailsChangesKey.LEASE, {
        ...leaseChanges,
        leaseStartDate: { value: date, error: leaseStartError },
        leaseEndDate: { value: leaseEndDate, error: leaseEndError },
      });
    },
    [leaseChanges, lease, setChanges],
  );

  const leaseEndDateChangeHanlder = useCallback(
    (date: Date | null) => {
      const leaseStartDate = leaseChanges?.leaseStartDate?.value ?? new Date(lease?.leaseStartDate ?? '');
      const leaseEndError = validateLeaseEndDate(date, leaseStartDate);
      const isLeaseEndChanged = (date && format(date, DateFormats.WITH_DASHES)) !== lease?.leaseEndDate;

      if (!isLeaseEndChanged) {
        delete leaseChanges?.leaseEndDate;
        return setChanges(DetailsChangesKey.LEASE, leaseChanges);
      }

      leaseChanges && (leaseChanges.leaseEndDate = { value: date, error: leaseEndError });
      setChanges(DetailsChangesKey.LEASE, leaseChanges);
    },
    [leaseChanges, lease, setChanges],
  );

  const addressUnitChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target: { value } }) => {
      const error = validateAddressUnit(value);
      const addressUnit = value === lease?.addressUnit ? undefined : { value, error };
      if (addressUnit) {
        leaseChanges.addressUnit = addressUnit;
      } else {
        delete leaseChanges?.addressUnit;
      }

      setChanges(DetailsChangesKey.LEASE, leaseChanges);
    },
    [leaseChanges, lease, setChanges],
  );

  const monthlyRentChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target: { value } }) => {
      const monthlyRentValue = formatCurrencyToNumberString(value);
      if (monthlyRentValue === String(lease?.monthlyRent)) {
        delete leaseChanges?.monthlyRent;
      } else {
        const error = validateMonthlyRent(monthlyRentValue);
        leaseChanges && (leaseChanges.monthlyRent = { value: monthlyRentValue, error });
      }

      setChanges(DetailsChangesKey.LEASE, leaseChanges);
    },
    [leaseChanges, lease, setChanges],
  );

  const freeRentChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target: { value } }) => {
      const freeRentValue = formatCurrencyToNumberString(value);
      if (freeRentValue === String(lease?.freeRent)) {
        delete leaseChanges?.freeRent;
      } else {
        const error = validateFreeRent(freeRentValue);
        leaseChanges && (leaseChanges.freeRent = { value: freeRentValue, error });
      }

      setChanges(DetailsChangesKey.LEASE, leaseChanges);
    },
    [leaseChanges, lease, setChanges],
  );

  const prepaidRentChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target: { value } }) => {
      const prepaidRentValue = formatCurrencyToNumberString(value);
      if (prepaidRentValue === String(lease?.prepaidRent)) {
        delete leaseChanges?.prepaidRent;
      } else {
        const error = validatePrepaidRent(prepaidRentValue);
        leaseChanges && (leaseChanges.prepaidRent = { value: prepaidRentValue, error });
      }

      setChanges(DetailsChangesKey.LEASE, leaseChanges);
    },
    [leaseChanges, lease, setChanges],
  );

  const lgCoverageChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target: { value } }) => {
      const lgCoverageOverrideValue = formatCurrencyToNumberString(value);
      if (lgCoverageOverrideValue === String(application?.lgCoverageOverride)) {
        delete applicationChanges?.lgCoverageOverride;
      } else {
        const error = validatePrepaidRent(lgCoverageOverrideValue);
        applicationChanges && (applicationChanges.lgCoverageOverride = { value: lgCoverageOverrideValue, error });
      }

      setChanges(DetailsChangesKey.APPLICATION, applicationChanges);
    },
    [applicationChanges, application, setChanges],
  );

  const sdrCoverageChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target: { value } }) => {
      const sdrCoverageOverrideValue = formatCurrencyToNumberString(value);
      if (sdrCoverageOverrideValue === String(application?.sdrCoverageOverride)) {
        delete applicationChanges?.sdrCoverageOverride;
      } else {
        const error = validatePrepaidRent(sdrCoverageOverrideValue);
        applicationChanges && (applicationChanges.sdrCoverageOverride = { value: sdrCoverageOverrideValue, error });
      }

      setChanges(DetailsChangesKey.APPLICATION, applicationChanges);
    },
    [applicationChanges, application, setChanges],
  );

  const isCoverageInputHasDifferences = coverageInputDifferences && getTruthyValuesCount(coverageInputDifferences) > 0;

  const shouldResetStates = useMemo(() => {
    return !changes || !Object.keys(changes).length;
  }, [changes]);

  const screeningStatuses = useMemo(() => {
    return [
      ...(unknownScreeningStatusFlag === 'on'
        ? [
            {
              option: SCREENING_STATUSES.UNKNOWN,
              value: SCREENING_STATUSES.UNKNOWN,
              keyval: SCREENING_STATUSES.UNKNOWN,
            },
          ]
        : []),
      { option: SCREENING_STATUSES.APPROVED, value: SCREENING_STATUSES.APPROVED, keyval: SCREENING_STATUSES.APPROVED },
      {
        option: SCREENING_STATUSES.CONDITIONAL,
        value: SCREENING_STATUSES.CONDITIONAL,
        keyval: SCREENING_STATUSES.CONDITIONAL,
      },
      { option: SCREENING_STATUSES.DENIED, value: SCREENING_STATUSES.DENIED, keyval: SCREENING_STATUSES.DENIED },
    ];
  }, [unknownScreeningStatusFlag]);

  const screeningStatusField = useMemo(() => {
    return displayScreeningStatusField === 'on'
      ? [
          {
            key: 'llScreeningStatusBox',
            label: 'LL Screening Status',
            value: (
              <EditableField
                key="editableLlScreeningStatus"
                data-testid="ll_screening_status_editable"
                editMode-testid="edit_ll_screening_status"
                error={applicationChanges?.screeningStatus?.error}
                resetStates={shouldResetStates}
                value={application?.screeningStatus ?? '--'}
                customEditComponent={
                  <Select
                    required={false}
                    defaultValue={
                      changes?.[DetailsChangesKey.APPLICATION]?.screeningStatus?.value ??
                      (application?.screeningStatus as string)
                    }
                    placeholder={
                      changes?.[DetailsChangesKey.APPLICATION]?.screeningStatus?.value ??
                      (application?.screeningStatus as string)
                    }
                    options={screeningStatuses}
                    optionRender={(o) => (
                      <div
                        key={(o as any).keyval}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          textTransform: 'none',
                          maxHeight: '1rem',
                        }}
                      >
                        <div>{o.value}</div>
                      </div>
                    )}
                    onChange={(e) => screeningStatusChangeHandler(e)}
                  />
                }
              />
            ),
          },
        ]
      : [];
  }, [
    application,
    displayScreeningStatusField,
    screeningStatuses,
    changes,
    applicationChanges,
    screeningStatusChangeHandler,
    shouldResetStates,
  ]);

  const editableLeaseData = useMemo(() => {
    const leaseStartDateValue = leaseChanges?.leaseStartDate?.value
      ? format(leaseChanges?.leaseStartDate?.value, DateFormats.WITH_SLASHES)
      : lease?.leaseStartDate
      ? formatIsoStringDate(lease?.leaseStartDate)
      : undefined;
    const leaseStartDateSelected =
      leaseChanges?.leaseStartDate?.value ??
      (lease?.leaseStartDate ? new Date(formatIsoStringDate(lease?.leaseStartDate)) : undefined);

    const leaseEndDateValue = leaseChanges?.leaseEndDate?.value
      ? format(leaseChanges?.leaseEndDate?.value, DateFormats.WITH_SLASHES)
      : lease?.leaseEndDate
      ? formatIsoStringDate(lease?.leaseEndDate)
      : undefined;
    const leaseEndDateSelected =
      leaseChanges?.leaseEndDate?.value ??
      (lease?.leaseEndDate ? new Date(formatIsoStringDate(lease?.leaseEndDate)) : undefined);

    return lease
      ? [
          {
            label: <b>Lease Start</b>,
            value: (
              <EditableField
                bold
                data-testid="manual_lease_start"
                editMode-testid="edit_lease_start"
                error={leaseChanges?.leaseStartDate?.error}
                resetStates={shouldResetStates}
                value={leaseStartDateValue}
                icon={coverageInputDifferences?.leaseStartDate ? 'warning' : undefined}
                customEditComponent={
                  <Datepicker
                    required
                    name="Lease Start"
                    invalid={Boolean(leaseChanges?.leaseStartDate?.error)}
                    startYear={new Date().getFullYear()}
                    endYear={MAX_DATE_YEAR}
                    selected={leaseStartDateSelected}
                    placeholderText={DateFormats.PLACEHOLDER}
                    dateFormat={DateFormats.WITH_SLASHES}
                    onChange={leaseStartDateChangeHandler}
                  />
                }
              />
            ),
            key: 'Lease Start',
          },
          {
            label: <b>Lease End</b>,
            value: (
              <EditableField
                bold
                data-testid="manual_lease_end"
                editMode-testid="edit_lease_end"
                error={leaseChanges?.leaseEndDate?.error}
                resetStates={shouldResetStates}
                value={leaseEndDateValue}
                editing={Boolean(leaseChanges?.leaseEndDate)}
                icon={coverageInputDifferences?.leaseEndDate ? 'warning' : undefined}
                customEditComponent={
                  <Datepicker
                    required
                    name="Lease End"
                    invalid={Boolean(leaseChanges?.leaseEndDate?.error)}
                    startYear={new Date().getFullYear()}
                    endYear={MAX_DATE_YEAR}
                    placeholderText={DateFormats.PLACEHOLDER}
                    dateFormat={DateFormats.WITH_SLASHES}
                    selected={leaseEndDateSelected}
                    onChange={leaseEndDateChangeHanlder}
                  />
                }
              />
            ),
            key: 'Lease End',
          },
          {
            label: 'Unit',
            value: (
              <EditableField
                data-testid="manual_lease_unit"
                editMode-testid="edit_lease_unit"
                label="Unit"
                error={leaseChanges?.addressUnit?.error}
                resetStates={shouldResetStates}
                value={formatField(leaseChanges?.addressUnit?.value ?? lease.addressUnit)}
                icon={coverageInputDifferences?.addressUnit ? 'warning' : undefined}
                onChange={addressUnitChangeHandler}
              />
            ),
            key: 'Unit',
          },
          {
            label: 'Monthly Rent',
            value: (
              <EditableField
                data-testid="manual_lease_monthly_rent"
                editMode-testid="edit_lease_monthly_rent"
                label="Monthly Rent"
                controlled={false}
                error={leaseChanges?.monthlyRent?.error}
                resetStates={shouldResetStates}
                value={formatField(leaseChanges?.monthlyRent?.value ?? lease.monthlyRent, true)}
                icon={coverageInputDifferences?.monthlyRent ? 'warning' : undefined}
                onChange={monthlyRentChangeHandler}
              />
            ),
            key: 'Monthly Rent',
          },
        ]
      : [];
  }, [
    lease,
    coverageInputDifferences,
    leaseChanges,
    leaseStartDateChangeHandler,
    leaseEndDateChangeHanlder,
    addressUnitChangeHandler,
    monthlyRentChangeHandler,
    shouldResetStates,
  ]);

  const freePrepaidRentFields = useMemo(() => {
    return lease
      ? [
          {
            label: 'Free Rent',
            value: (
              <EditableField
                data-testid="manual_lease_free_rent"
                editMode-testid="edit_lease_free_rent"
                label="Free Rent"
                controlled={false}
                error={leaseChanges?.freeRent?.error}
                resetStates={shouldResetStates}
                value={formatField(leaseChanges?.freeRent?.value ?? lease.freeRent, true)}
                icon={coverageInputDifferences?.freeRent ? 'warning' : undefined}
                onChange={freeRentChangeHandler}
              />
            ),
            key: 'Free Rent',
          },
          {
            label: 'Prepaid Rent',
            value: (
              <EditableField
                data-testid="manual_lease_prepaid_rent"
                editMode-testid="edit_lease_prepaid_rent"
                label="Prepaid Rent"
                controlled={false}
                error={leaseChanges?.prepaidRent?.error}
                resetStates={shouldResetStates}
                value={formatField(leaseChanges?.prepaidRent?.value ?? lease.prepaidRent, true)}
                icon={coverageInputDifferences?.prepaidRent ? 'warning' : undefined}
                onChange={prepaidRentChangeHandler}
              />
            ),
            key: 'Prepaid Rent',
          },
        ]
      : [];
  }, [
    lease,
    coverageInputDifferences,
    leaseChanges,
    freeRentChangeHandler,
    prepaidRentChangeHandler,
    shouldResetStates,
  ]);

  const coverageFields = useMemo(() => {
    return application && coverageFieldsEnabled === 'on'
      ? [
          {
            label: 'LG Coverage (Mo.)',
            value: (
              <EditableField
                data-testid="manual_lease_lg_coverage"
                editMode-testid="edit_lease_lg_coverage"
                label="LG Coverage (Mo.)"
                controlled={false}
                error={applicationChanges?.lgCoverageOverride?.error}
                resetStates={shouldResetStates}
                value={formatField(
                  applicationChanges?.lgCoverageOverride?.value ??
                    application?.lgCoverageOverride ??
                    application?.quote?.coverageMonths,
                )}
                icon={coverageInputDifferences?.lgCoverageOverride ? 'warning' : undefined}
                onChange={lgCoverageChangeHandler}
              />
            ),
            key: 'LG Coverage (Mo.)',
          },
          {
            label: 'SDR Amount',
            value: (
              <EditableField
                data-testid="manual_lease_sdr_coverage"
                editMode-testid="edit_lease_sdr_coverage"
                label="SDR Amount"
                controlled={false}
                error={applicationChanges?.sdrCoverageOverride?.error}
                resetStates={shouldResetStates}
                value={formatField(
                  applicationChanges?.sdrCoverageOverride?.value ??
                    application?.sdrCoverageOverride ??
                    application?.quote?.amountSDR,
                  true,
                )}
                icon={coverageInputDifferences?.sdrCoverageOverride ? 'warning' : undefined}
                onChange={sdrCoverageChangeHandler}
              />
            ),
            key: 'SDR Amount',
          },
        ]
      : [];
  }, [
    application,
    coverageInputDifferences,
    applicationChanges,
    coverageFieldsEnabled,
    lgCoverageChangeHandler,
    sdrCoverageChangeHandler,
    shouldResetStates,
  ]);

  const evaluationButton = useMemo(() => {
    const showEvaluateButton = application?.normalizedFields?.autoUWVersion === 2;

    const oldButtonEnabled =
      application?.lease?.leaseStartDate &&
      application?.lease?.leaseEndDate &&
      application?.lease?.monthlyRent &&
      application?.screeningStatus &&
      allowedApplicationStatusesForPreApprovalEvaluation.includes(application.status) &&
      (useDefaultRulesForUnkownScreeningStatusFlag === 'on'
        ? true
        : allowedScreeningStatusesForFinalEvaluation.includes(application?.screeningStatus));

    const buttonEnabledwAddrUnit =
      application?.lease?.leaseStartDate &&
      application?.lease?.leaseEndDate &&
      application?.lease?.monthlyRent &&
      application?.lease?.addressUnit &&
      application?.screeningStatus &&
      application?.status &&
      allowedApplicationStatusesForPreApprovalEvaluation.includes(application.status) &&
      (useDefaultRulesForUnkownScreeningStatusFlag === 'on' ||
        allowedScreeningStatusesForFinalEvaluation.includes(application.screeningStatus));

    const resultStatus =
      evaluationData?.approvalStatus === 'Approved'
        ? 'approved'
        : evaluationData?.approvalStatus === 'Denied'
        ? 'denied'
        : 'failed';

    return showEvaluateButton
      ? [
          {
            label: (
              <>
                <Box display="flex" justifyContent="start" py="sm">
                  <Button
                    bType="primary"
                    size="s"
                    disabled={addressUnitRequired === 'on' ? !buttonEnabledwAddrUnit : !oldButtonEnabled}
                    onClick={async () => evaluateApplication({ variables: { applicationId: application.id } })}
                  >
                    {evaluationLoading ? (
                      <>
                        <Icon name="loading" color="other.white" marginRight="xs" />
                        Loading
                      </>
                    ) : (
                      'Evaluate Application'
                    )}
                  </Button>
                </Box>
                <EvaluationResultModal
                  isOpen={isEvaluationResultModalOpen}
                  closeModal={() => setIsEvaluationResultModalOpen(false)}
                  continueHandler={async () =>
                    processEvaluatedApplication({
                      variables: { applicationId: application.id, approvalStatus: resultStatus },
                    })
                  }
                  data-testid="evaluate_result_modal"
                  evaluationResultStatus={resultStatus}
                  errorMessage={evaluationData?.error?.message}
                  isLoading={processingLoading}
                />
              </>
            ),
            key: 'Evaluate Button',
          },
        ]
      : [];
  }, [
    application,
    evaluationData,
    evaluationLoading,
    isEvaluationResultModalOpen,
    evaluateApplication,
    setIsEvaluationResultModalOpen,
    processEvaluatedApplication,
    processingLoading,
    useDefaultRulesForUnkownScreeningStatusFlag,
    addressUnitRequired,
  ]);

  const data = [
    ...screeningStatusField,
    ...editableLeaseData,
    ...(displayNewDetailsFeatureFlag === 'on' ? freePrepaidRentFields : []),
    ...(coverageFieldsEnabled === 'on' ? coverageFields : []),
    ...evaluationButton,
  ];

  return (
    <InfoContainer
      color="neutral.main"
      title="Coverage Input"
      data-testid="lease_details_card"
      data={data}
      borderColor={isCoverageInputHasDifferences ? 'warning.main' : 'table.disabledBackground'}
      titleContainerProps={{
        pseudoAfterBackgroundCssVar: isCoverageInputHasDifferences ? '--color-warning-main' : '--color-primary-main',
      }}
      {...props}
    />
  );
};
